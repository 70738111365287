<template>
  <div>
    <van-cell-group title="筛选">
      <van-dropdown-menu>
        <van-dropdown-item v-model="sea.SEA_STATE" @change="filter" :options="[
            {text:'全部状态', value:0},
            {text:'已审核', value:1},
            {text:'未审核', value:2},]" />
      </van-dropdown-menu>
      <!-- <van-search v-model="sea.KEY" placeholder="请输入搜索关键字" @search="filter" @clear="filter" /> -->
      <van-field label="名称" placeholder="请输入搜索名称" v-model="sea.THING_NAME" />
      <van-cell title="日期" :value="sea.START_DATE" @click="date.show=true" is-link />
      <van-popup v-model="date.show" round position="bottom">
        <van-datetime-picker type="date" @cancel="date.show = false" @confirm="timeConfirm" />
      </van-popup>
    </van-cell-group>
    <van-divider />
    <van-empty description="暂无数据" v-if="lst.da.length==0" />
    <van-list :finished="lst.finish" @load="loadPage" v-model="lst.loading">
      <div v-for="item in lst.da" :key="item.ID" class="card" @click="edit(item)">
        <van-row :gutter="8" style="line-height: 1.8em">
          <van-col :span="10" style="text-align: center">
            <van-image :src="item.IMAGEKVS[0].Value" v-if="item.IMAGEKVS.length>0"
              @click="prvImage(item.item.IMAGEKVS[0].Value)" />
            <van-icon name="photo-o" v-else size="80px" />
          </van-col>
          <van-col :span="14">
            <div><b>名称:</b>{{item.THING_NAME}}</div>
            <div><b>位置:</b>{{item.LOCATION}}</div>
            <div><b>数量:</b>{{item.COUNT}}</div>
            <div><b>金额:</b>{{item.AMOUNT}}</div>
          </van-col>
        </van-row>
        <van-divider />
        <div><b>状态:</b>{{item.STATUS_TEXT}}</div>
        <div style="margin-top: 5px;"><b>报损原因:</b>{{item.CAUSE}}</div>
        <div style="margin-top: 5px;"><b>备注:</b>{{item.NOTE}}</div>
        <van-divider />
        <div style="margin-top: 10px;display: flex;align-items: center;justify-content: space-between">
          <div><b>申报人:</b>{{item.CREATED_NAME}}</div>
          <div><b>申报日期:</b>{{item.CREATED_TIME
            }}</div>
        </div>
        <!--已审批样式-->
        <div style="margin-top: 10px;display: flex;align-items: center;justify-content: space-between"
          v-if="item.APPROVE_NAME">
          <div><b>审批人:</b>{{item.APPROVE_NAME}}</div>
          <div><b>审批日期:</b>{{item.APPROVE_TIME}}</div>
        </div>
        <!--待审批-->
        <div class="btns" style="margin-top: 20px;display: flex;align-items: center;justify-content: space-around"
          v-if="item.STATUS==10">
          <van-button plain type="primary" size="normal" round @click="pass(item.ID)" style="width: 25%">通过
          </van-button>
          <van-button plain type="danger" size="normal" round @click="reject(item.ID)" style="width: 25%">拒绝
          </van-button>
          <van-button plain type="info" size="normal" round @click="cancel(item.ID)" style="width: 25%">撤消
          </van-button>
        </div>
      </div>
    </van-list>
    <van-popup v-model="reson.show" position="bottom" round>
      <div style="padding: 20px;text-align: center">拒绝原因</div>
      <van-field border v-model="reson.val" type="textarea" rows="3" autosize show-word-limit maxlength="100"
        placeholder="请输入拒绝原因" />
      <div style="padding:10px 50px">
        <van-button type="primary" size="large" round text="确认提交" loading-text="正在提交" @click="save" />
      </div>
    </van-popup>
  </div>
</template>
<style scoped>
  .card {
    margin: 10px;
    border-radius: 15px;
    background: #fff;
    padding: 10px;
    font-size: 14px;
  }

  .card div b {
    color: #999;
    font-weight: normal;
    margin-right: 5px
  }

  .pro {
    padding: 5px
  }

  .pro button {
    margin: 0px 5px;
    width: 90px;
  }

  .mender {
    border-bottom: solid 1px #ebedf0;
    text-align: left;
    padding-left: 5px;
  }

  .mender span {
    margin: 5px;
  }
</style>
<script>
  import { ImagePreview, Dialog } from 'vant';
  import moment from "moment";

  export default {
    components: { [ImagePreview.Component.name]: ImagePreview.Component },
    data() {
      return {
        sea: {
          PAGE_SIZE: 5,
          PAGE_INDEX: -1,
          SEA_TYPE: this.$route.params.for
        },
        lst: {
          da: [],
          loading: false,
          finish: false
        },
        cat: {
          show: false,
          ls: [],
          txt: ""
        },
        date: {
          show: false,
          val: ""
        },
        reson: {
          show: false,
          val: ''
        }
      }
    },
    created() {
      // console.log(this.sea.SEA_TYPE)
    },
    methods: {
      pass() {
        Dialog.confirm({
          title: "请确认通过",
        }).then(() => { })
      },
      reject() {
        this.reson.show = true
      },
      save() { },
      timeConfirm(val) {
        this.time.show = false;
        this.model.date = val;
      },

      catSel({ selectedOptions }) {
        this.cat.show = false;
        this.cat.txt = selectedOptions.map((option) => option.NAME).join('/');
        this.filter();
      },
      dateSel(d) {
        this.sea.DTS = moment(d[0]).format('YYYY-MM-DD');
        this.sea.DTE = moment(d[1]).format('YYYY-MM-DD')
        this.date.show = false;
        this.date.val = this.sea.DTS + ' 至 ' + this.sea.DTE;
        this.filter();
      },

      filter() {
        this.lst.finish = true;
        this.lst.da.length = 0;
        this.lst.finish = false;
        this.sea.PAGE_INDEX = 0;
        this.getList();
      },
      loadPage() {
        this.sea.PAGE_INDEX += 1;
        this.getList();
      },
      getList() {
        let self = this;
        this.whale.remote.getCollection({
          url: "/api/Mobile/LOSS/RecordApi/GetList",
          data: this.sea,
          completed(its, n) {
            self.lst.da.push(...its.ITEMS);
            self.lst.loading = false;
            if (self.lst.da.length >= n) self.lst.finish = true;
          }
        })
      },
      edit(item) {
        this.$router.push({ path: "/scrap/detail", query: { id: item.ID, for: this.sea.SEA_TYPE } });
      },
      prvImage(url1, url2) {
        let us = [];
        if (url1 != undefined) us.push(url1)
        if (url2 != undefined) us.push(url2)
        ImagePreview(us)
      },

      cancel(id) {
        let self = this;
        Dialog.confirm({
          title: "撤消维修",
          message: "真的要撤消这次维修吗？",
        }).then(() => {
          this.whale.remote.getResult({
            url: "/api/Mobile/FIX/NoteApi/Cancel",
            data: {
              ID: id,
            },
            finally() {
            },
            completed() {
              self.filter();
            }
          })
        })
      },
    }
  }
</script>